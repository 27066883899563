.Description {
  p {
    color: var(--blings_blue);
    font-size: 10px;
    margin: 0;
    padding: 0;
  }
}
.Divider {
  margin: 0 !important;
}
.TextLayer {
  .Panel {
    & > div.ant-collapse-header {
      padding: 1rem 0.2rem !important;
    }
    & > div.ant-collapse-content > .ant-collapse-content-box {
      padding: 0rem 1rem;
    }
    background-color: #3741460a;
    margin: 0.5rem 0rem;
    border-radius: 3px;
    &.ant-collapse-item:hover {
      border-left: 4px solid rgba(255, 21, 77, 0.1);
    }
    &.ant-collapse-item-disabled:hover {
      border-left: none;
    }
    &.ant-collapse-item-active {
      border-left: 4px solid var(--blings_pink) !important;
    }
  }
}
