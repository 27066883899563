//@import '~antd/dist/antd.css';
@import "./consts";
html,
body,
#root,
.App {
  height: 100%;
  overflow: auto;
}
a {
  color: var(--blings_teal);
  &:hover {
    color: var(--blings_pink);
  }
}

* {
  &::-webkit-scrollbar {
    background-color: #fff !important;
    width: 16px !important;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff !important;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0 !important;
    border-radius: 16px !important;
    border: 4px solid #fff !important;
    min-height: 35px;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none !important;
  }
}
.project-tab-padding {
  padding: 2rem;
}

.ant-btn {
  padding: 0.4rem 1.2rem;
  border-radius: 9px;
  font-weight: 600;
  line-height: normal;
  height: inherit;
  &.ant-btn-primary:hover {
    background-color: var(--blings_pink_hover);
  }
  &.ant-btn-circle {
    padding: 0.3rem;
  }
}
.ant-layout {
  & > div {
    padding: 1rem;
  }
}
.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ant-col:first-child {
  padding-left: 0 !important;
}

.ant-col:last-child {
  padding-right: 0 !important;
}

// outside of .App since antd <Select> renders outside
.fontW900 {
  font-weight: 900;
}
.fontW700 {
  font-weight: 700;
}
.fontW600 {
  font-weight: 600;
}

.ant-card {
  height: 100%;
}

.Main {
  .page-layout-padding {
    padding: $layoutpaddingsides;
    height: 100%;
    overflow: auto;
  }
}

.header {
  background: coral;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.spinner {
  text-align: center;
  margin-top: 10rem;
  .Rotate {
    margin-right: 0.5rem;
    height: 18px;
    width: 18px;
    animation: rotation 2s linear infinite;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
