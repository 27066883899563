.JSONForm .MediaWidget .value-text a {
  color: inherit;
}

.DemoPage {
  .demo_iframe {
    padding: 4px;
    background: #4848485c;
    border: 4px solid var(--blings_primary_highlight);
    border-radius: 9px;
  }

  .JSONForm {
    max-height: 75vh;
  }
  .JSONForm > .form-group {
    max-height: 80vh;
    flex-wrap: nowrap;
  }
  .JSONForm > .form-group > .ant-col {
    flex: 0 0 auto;
  }

  > .ant-row-flex {
    max-width: 100%;
  }

  .__Blings__player__container {
    z-index: 0;
  }
  position: relative;
  & > div {
    margin: auto;
    max-height: 100%;
  }
  .Horizontal {
    text-align: center;
    max-width: 100% !important;
  }
  .Vertical {
    position: absolute;
    left: 30%;
  }
}
