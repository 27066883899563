.responsive-inline-frame {
  max-width: 900px;
  min-width: 500px;
  margin: auto;



  .responsive-inline-frame-inner {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 0;
  }

  .responsive-inline-frame-inner iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
