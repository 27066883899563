.IntegrationLink {
  .link-container {
    background-color: white;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    max-height: 100px;
    scroll-behavior: auto;
    overflow-y: auto;
    display: flex;

    .link {
      max-width: 100%;
      display: block;
      padding: 10px 20px;

      .token {
        color: var(--blings_primary);
        font-weight: 700;
      }
    }
  }
}