.notification-icon-holder{
    width: 36px;
    height: 36px;
    background-color: #F7F7F8;
    border-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification-icon{
    transform: scale(1);
    transition: 0.3s all ease-in-out;
}

.notification-icon:hover{
    transform: scale(1.3);
    transition: 0.3s all ease-in-out;
}