@import "../consts";

.Main {
  .SubHeader {
    font-size: 1.8rem;
    color: var(--blings_teal);
    opacity: 0.3;
    font-weight: 700;
  }
  height: 100%;
  overflow: auto;
  .Container {
    display: flex;
    height: 93vh;
    .Scenes {
      background-color: var(--blings_background3);
      overflow: auto;
      overflow-x: hidden;
      padding: 0.6 0.5rem;
      width: 11vw;
      max-width: 160px;
      min-width: 8rem;
    }
    .Edit {
      overflow: auto;
      overflow-x: hidden;
      height: 100%;
      width: 45vw;

      .SubHeader {
        margin: 0.8rem;
        opacity: 0.6;
      }
      .EditViewHeader {
        .SubHeader {
          margin: 0.8rem 0;
          overflow: hidden;
          white-space: nowrap;
        }
        display: flex;
        align-items: center;
        gap: 0.3rem;
        justify-content: space-between;
        .SearchHeader {
          width: 50%;
          .Search {
            width: 100%;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    .Demo {
      background: var(--blings_background3);
      width: 45vw;
      .TopBar {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 100%;
        gap: 0rem 1rem;
        margin: 0.8rem;
      }
    }
  }
}
