/*
    Create a custom scrollbar for the notification dropdown menu
    The only id that should use this is #scrollable-notification-div
    And set the color of the scrollbar to red
*/
.notification-bell-icon {
  color: "#A9B6C3";
  transition: all 0.15s ease-in-out;
}

.notification-bell-icon:hover {
  color: #e2063b;
  transition: all 0.15s ease-in-out;
}

#scrollable-notification-div {
  max-height: 50vh;
  width: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}

#scrollable-notification-div::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#scrollable-notification-div::-webkit-scrollbar-thumb {
  background-color: #a9b6c3;
  border-radius: 10px;
  border: 3px solid #f5f5f5;
}

#scrollable-notification-div::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
