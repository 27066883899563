.Search {
  width: 100%;
  margin-bottom: 1rem;
  .SearchIcon {
    color: var(--blings_pink);
    opacity: 0.6;
  }
  .SearchInput {
    border-bottom: 1px var(--blings_blue) solid;
    padding: 0.3rem 0rem;
  }
}
