.SearchIcon {
  color: var(--blings_pink);
  opacity: 0.6;
  font-size: 1rem;
}
.SearchInput {
  border-bottom: 1px var(--blings_blue) solid !important;
  border-radius: 0;
  padding: 0.3rem 0rem;
  margin-bottom: 0.2rem;
  input {
    font-size: 1rem;
  }
}

.ProjectItem {
  padding: 0.3rem 0;
  border-radius: 3px;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    background-color: #F5F5F5;
  }
}
