.EditView {
  margin: 0.8rem;
  #sceneName {
    color: var(--blings_pink);
    font-weight: 600;
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
  .DynamicText {
    margin-top:2rem;
  }
}
