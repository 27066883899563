.ProjectsGallery {
  height: 100%;
  overflow-y: scroll;

  .Project-Gallery-Header {
    display: flex;
    .Left-Side {
      margin: 1rem 0rem;
      .SubHeader {
        font-size: 2rem;
        color: var(--blings_pink);
        font-weight: 700;
      }
    }
    .Right-Side {
      margin-left: auto;
      align-self: center;
    }
  }
  .thumb {
    //width: 2rem;
  }
  .project-card {
    .ant-card-body {
      height:6rem;
    }
  }

  .ant-col {
    float: right;
    margin-bottom: 16px;
  }
  .new-project-card {
    height: 100%;
    padding-top: 4rem;
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 1rem;
      .new-icon {
        font-size: 24px;
      }
      .new-project-card-meta {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .ant-card-cover img {
    //min-height: 220.5px;
    //max-height: 220.5px;
    height: 10rem;
    background: black;
    background: var(--blings_secondary);
    object-fit: contain;
  }
}
