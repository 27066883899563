.analytics {
  --analytics_view_width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 100%;

  h1,
  h2 {
    color: var(--blings-teal);
  }

  //background-color: white;
  .title {
    display: flex;
    flex-direction: row;
    height: 2rem;
    justify-content: flex-start;
    margin-bottom: 1rem;

    h1 {
      margin-right: 1rem;
    }
  }
  .view-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 2rem;
    justify-content: space-between;
    margin-bottom: 1rem;

    h2 {
      margin-right: 1rem;
      span {
        font-weight: bold;
      }
    }
  }

  .views-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 2em 2em;
    .analytics-view {
      min-width: 15vw;
      //border: 1px solid gray;
      background-color: white;
      border-radius: 3px;
      padding: 10px;
      overflow: hidden;
    }
    .first-row {
      // min-width: 83vw;
      // max-width:84vw;
      width: 100%;
      display: flex;
      gap: 2rem;
      .funnel {
        // width:  calc(calc(100vw - var(--analytics_view_width)) * 2 / 3);
        flex: 1 2 0;
      }
      .top-cta {
        flex: 1 1 0;
        max-width: calc(calc(100vw - var(--analytics_view_width)) / 3);
      }
    }
    .sessions {
      width: calc(
        calc(100vw - var(--analytics_view_width)) / 3
      ); //second row divide by three
    }

    .users {
      width: calc(calc(100vw - var(--analytics_view_width)) / 3);
    }
    .watch-time {
      width: calc(calc(100vw - var(--analytics_view_width)) / 3);
      height: 400px;
    }
    .sessions-per-device {
      width: calc(calc(100vw - var(--analytics_view_width)) / 2);
    }
    .sessions-os {
      width: calc(calc(100vw - var(--analytics_view_width)) / 2);
    }

    .map {
      width: calc(100vw - 600px);
      margin-left: 10vw;
    }

    table {
      tbody {
        tr:hover > td {
          background-color: var(--blings_pink);
        }

        tr > td {
          border-radius: 3px;
          border-color: #a9b6c34d;
          //font-family:"Open Sans"
        }
      }

      thead > tr > th {
        //font-family: "inter";
      }
    }
  }

  .tooltip {
    background-color: var(--blings-teal);
    color: "white";
    padding: ".5rem";
    border-radius: "5px";
    position: "relative";

    .color-block {
      width: 10px;
      height: 10px;
    }
  }

  .tooltip::after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    transform: translate(100%, 50%) rotate(0.25turn);
    background-color: var(--blings-teal);
  }
}
