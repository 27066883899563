.SiteIntegration {
  .page-title {
    color: #37414699;
  }

  .tab-buttons {
    display: flex;
    flex-direction: row;

    .tab-button {
      background-color: #F7F7F8;
      width: 223px;
      min-width: 223px;
      height: 82px;
      border-radius: 4px;
      margin: 16px;
      padding: 10px 21px;
      cursor: pointer;

      .title {
        color: var(--that_gray);
        font-size: 12px;
        font-weight: 700;
      }

      .subtitle {
        color: var(--that_gray);
        font-size: 12px;
        line-height: 14px;
      }
    }

    .tab-button.selected-tab-button {
      border: solid 1px var(--blings_pink);
      background-color: #FFE8EE;
    }

    .tab-button:hover:not(.selected-tab-button) {
      background-color: #EBECEC;
      border: none;
    }
  }
}