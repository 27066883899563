.BlingsInput {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-bottom: 1rem;
  padding: 7px 7px 2px 13px;
  border: 1px solid #e0e0e0;
  // justify-content: space-between;
  border-radius: 3px;
  height: auto;
  max-height: 600px;
  background-color: white;
  overflow: auto;

  .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-right: 3px;
    height: auto;
    // max-height: 68px;
    overflow: auto;

    .label {
      display: flex;
      color: #a9b6c3;
      font-size: 10px;
    }

    .input {
      display: flex;
      max-height: 100px;
      width: 100%;
      border: 0;
      font-size: 14px;
      resize: none;
      min-height: 17px;
      height: 100%;
      height: 24px;

      &:focus {
        outline: none;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: row;

    .restore-button {
      cursor: pointer;
      .Restore {
        color: #212121;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        border-radius: 5px;
        background-color: #7d959c33;
        &:hover {
          color: var(--blings_pink);
          background-color: #fbe9ed;
        }
      }
      &.disabled {
        cursor:default;
        .Restore {
          background-color: #A9B6C333;
          color: #cccccc;
        }
      }
    }

    .upload-button {
      cursor: pointer;
      background: transparent;
      border: none;

      .ant-upload.ant-upload-btn {
        padding: 0;
        display: flex;
        height: auto;
      }
    }
  }
}
