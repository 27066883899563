.FrameIndicator{
  padding-top: 1rem;
  display: flex;
  align-items:center;
  justify-content:flex-start;
  width: 100%;
  min-width: 5rem;
  text-align: right;
  margin-right: 1rem;
  color: white;

  .FrameIndicatorInput{
    margin-right: .5rem;
  }
  .FrameIndicatorMax{
    color: black;
  }
}
