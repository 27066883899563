.Search {
    width: 25rem;
    padding-right: 8px;
    .SearchIcon {
      color: var(--blings_pink);
      opacity: 0.6;
      font-size:1.2rem;
    }
    .SearchInput {
      border-bottom: 1px var(--blings_blue) solid;
      padding: 0.3rem 0rem;
      input {

          font-size:1rem;
        }
    }
  }
  