.LandingPageIntegration {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;

  .body {
    display: flex;
    flex-direction: row;

    .content {
      width: 500px;
      display: flex;
      flex-direction: column;

      .form {
        flex-direction: column;

        .form-subtitle {
          font-size: 1rem;
          color: var(--blings_purple);
        }

        .ant-form-item-control-input-content {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .footer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        align-items: center;

      }
    }
  }

  .video-container {
    margin: auto;
    padding: 0 5rem;

    video {
      width: 100%;
    }
  }
}

li {
  list-style: circle;
}






.tab-container {
  position: relative;

  .svg {
    color: var(--blings_pink);
  }

  .content {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 35%;
    align-items: center;
    padding-left: 8%;

    .icon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }

    .title {
      font-size: 18px;
      color: white;
    }
  }

}

.tooltip-integration {
  max-width: 150px;
}