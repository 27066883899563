.AllScenes {
  text-align: center;
  text-decoration: underline;
  margin: 1rem 0;
  &.IsSelected {
    & > a {
      color: var(--blings_pink);
    }
    font-weight: 700;
    text-decoration: none;
  }
}
.SceneCard {
  width: 85%;
  scroll-margin: 1rem;
  cursor: pointer;
  margin: 1rem auto;
  height: 60px;
}
