.Card {
  .ant-card-meta-title {
    font-size: 12px;
  }
  .ant-card-body {
    padding: 24px 12px;
  }
  &:hover {
    border-left: 4px solid rgba(255, 21, 77, 0.1);
  }
}

.CardSelected {
  border-left: 4px solid var(--blings_pink) !important;
  background: rgba(255, 21, 77, 0.1);
}
