.ProjectSettings {
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-tabpane {
    .ant-divider-horizontal {
      margin: 0 0 0.75rem 0;
    }
  }
}
