.Update-Project {
  .schemas {
    margin: 1rem 0;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    .schema-card {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
  .BlingsBtn {
    margin-bottom: 1rem;
  }
  .Icon {
    cursor: pointer;
    color: #212121;
    background-color: #7d959c33;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    &.enabled {
      &:hover {
        color: var(--blings_pink);
        background-color: #fbe9ed;
      }
    }
    &.disabled {
      cursor: default;
      background-color: #a9b6c333;
      color: #cccccc;
    }
  }
}
.Project-Schemas {
  .BlingsBtn {
    margin-bottom: 1rem;
  }
}
