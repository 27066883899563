.CrmIntegration {
  .DataIntegration {
    display: flex;
    flex-direction: row;
    width: 600px;

    .video-container {
      margin: auto;
      padding: 0 5rem;
    }
  }
}