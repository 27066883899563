.JSONForm .MediaWidget .value-text a {
  color: inherit;
}

.DemoPage {
  .text {
    padding: 2rem;
  }
  .demo_iframe {
    padding: 4px;
    background: #4848485c;
    border: 4px solid var(--blings_primary_highlight);
    border-radius: 9px;
  }

  .JSONForm {
    max-height: 75vh;
  }
  .JSONForm > .form-group {
    max-height: 80vh;
    flex-wrap: nowrap;
  }
  .JSONForm > .form-group > .ant-col {
    flex: 0 0 auto;
  }
  //.JSONForm {
  //  .ant-form-item-label {
  //    font-weight: 500;}
  //  .field-array > .ant-form-item-control-wrapper {
  //    padding-left: 1rem;
  //  }
  //  .field-object-1 > .field {
  //    padding-left: 1rem;
  //
  //  }
  //  .field-boolean {
  //    display: flex;
  //    > .ant-form-item-label {
  //      margin-right: 1rem;
  //    }
  //  }
  //  //flex: 1;
  //}

  > .ant-row-flex {
    max-width: 100%;
  }

  .__Blings__player__container {
    z-index: 0;
  }
}
