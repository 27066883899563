.Main-Content {
  position: fixed;
  top:50px;
  bottom:0;
  position:fixed;
  overflow-x:hidden;
  background-color: white;
  width: 100%;
  .inner-div{
    position:relative;
  }
}
.ArrowDown {
  color: var(--blings_pink);
  font-size: 16px;
}

.page-layout-padding {
  height: 100%;
  overflow: auto;
}
.Header {
  display: flex;
  align-items: center;
  border-bottom: 1px var(--blings_blue) solid;
  padding: 0rem 1rem;
  font-size: 12px;
  height: 7vh;
  min-height: 50px;
  position: fixed;
  max-height: 50px;
  width: 100%;
  top: 0;
  overflow: hidden;
  background-color: var(--blings_background);
  white-space: nowrap;
  z-index: 99;

  .ant-select {
    min-width: 12rem;
    .ant-select-selection-item {
      margin: 0rem 1.5rem;
      color: var(--blings_pink);
      font-weight: 700;
      // font-size: 1rem;
      align-self: center;
    }
  }

  #Logo {
    cursor: pointer;
    min-width: 32px;
  }
  .NavBar {
    display: flex;
    width: 100vw;
    gap: 1rem;
    .ProjectTitle {
      font-weight: 700;
      color: var(--blings_pink);
      font-size: 14px;
      cursor: pointer;
    }
    .LogoLink {
      align-self: center;
      & > div {
        height: 35px;
        width: 35px;
      }
    }
    .ProjectSearch {
      .ant-select-selector {
        padding: 0 11px 0 0;
        border-radius: 9px;
        background: inherit;
        border: 1px solid transparent;
        &:hover {
          border: 1px solid rgba(255, 21, 77, 0.1);
        }
        .ant-select-selection-item {
          margin: 0;
        }
      }
    }
    .HomePage {
      font-size: 14px;
    }
    .NavItem {
      &::after {
        // To avoid spacing changes when selected item becomes bold on select
        display: block;
        content: attr(id);
        font-weight: bold;
        height: 1px;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
      }
    }
    .Selected {
      color: var(--blings_pink);
      font-weight: 700;
      // text-decoration: underline;
    }
  }
  #Title {
    margin: 0 1rem 0 0;
    color: var(--blings_pink);
    font-weight: 700;
    // font-size: 1rem;
    align-self: center;
  }
  #PreviewButton {
    margin-left: auto;
    .DownArrow {
      margin-left: 0.5rem;
    }
  }
  #PublishButton {
    margin-right: 1rem;
  }
}
.view-live-version-popover {
  .view-live-version-select {
    min-width: 150px;

    .ant-select-selector {
      margin-right: 1rem;
    }
  }
}
.RightNav {
  .settings {
    height: 80%;
    cursor: pointer;
  }
  .notificationIcon {
    & > div {
      height: 100%;
    }
    height: 100%;
    .ant-badge {
      font-size: 20px;
    }
  }
  gap: 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-left: auto;
  #PublishButton {
    font-size: 12px;
    button:hover {
      background: var(--blings_pink_hover);
    }
  }
  #ExtraInfo {
    color: var(--blings_teal_60);
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 0.5rem;
  }
}
.overlayClass {
  &,
  .ant-popover-placement-bottomRight {
    padding-top: 0;
  }
  .ant-popover-inner-content {
    padding: 12px 16px 0 16px;
  }
}

.RenderStep {
  .dot1 {
    animation: visibility 3s linear infinite;
  }
  @keyframes visibility {
    0% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .dot2 {
    animation: visibility2 3s linear infinite;
  }
  @keyframes visibility2 {
    0% {
      opacity: 0;
    }
    21% {
      opacity: 0;
    }
    22% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .dot3 {
    animation: visibility3 3s linear infinite;
  }
  @keyframes visibility3 {
    0% {
      opacity: 0;
    }
    43% {
      opacity: 0;
    }
    44% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}