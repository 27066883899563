.Integration {
  display: flex;
  flex-direction: row;
  height: 93vh;

  .BlingsContainer {
    width: 600px;
  }

  .integration-tab-buttons {
    height: 100%;
    width: 180px;
    background-color: #FAF8F8;
    padding: 35px 40px;
    color: var(--blings_blue);

    .integration-tab-button {
      width: 100px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      cursor: pointer;

      .title {
        text-align: center;
        margin-top: 15px;
      }
    }

    .integration-tab-button:hover:not(.selected-integration-tab-button) {
      background-color: #EBECEC;
    }

    .selected-integration-tab-button {
      color: var(--blings_pink);
      background-color: #FFE8EE;
      border-left: solid 4px var(--blings_pink);
      border-radius: 3px;

      .title {
        font-weight: 700;
      }
    }
  }

  .integration-tabs-content {
    padding: 2rem;
    overflow: auto;
  }
}