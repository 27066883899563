.EditTextForm {
  border-radius: 4px;
  & > .ant-form-item {
    .TopTextBox {
      color: var(--blings_blue);
      text-align: end;
      margin-right: 0.2rem;
    }
    margin-bottom: 0.6rem;
  }
  .Buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  .TextArea {
    background-color: white;
    border: 1px solid rgba(255, 21, 77, 0.5);
    resize: none;
  }
}
